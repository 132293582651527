import React, {createContext, useState} from 'react'
import {MemoryRouter, Route, Routes} from 'react-router-dom'
import ScreenLoginOtherMethod from '@feature/common/components/LoginModal/screen/ScreenLoginOtherMethod'
import FindPasswordRoutes from '@feature/common/components/LoginModal/routes/FindPasswordRoutes/FindPasswordRoutes'
import RegisterRoutes from '@feature/common/components/LoginModal/routes/RegisterRoutes/RegisterRoutes'
import LoginRoutes from '@feature/common/components/LoginModal/routes/LoginRoutes/LoginRoutes'
import ScreenLogin from '@feature/common/components/LoginModal/screen/ScreenLogin/ScreenLogin'
import Script from 'next/script'
import {KAKAO_KEY} from '@constant/auth'

interface IProps {
    closeModal: () => void
}

export interface ILoginModalProps {
    closeModal: () => void
}

export const SetLoginStateContext = createContext({
    isKakaoInit: false,
})

const LoginModalRouter: React.FC<IProps> = ({closeModal}) => {
    const [isKakaoInit, setIsKakaoInit] = useState(false)

    return (
        <>
            <Script
                crossOrigin={'anonymous'}
                src="/kakao.min.js"
                strategy={'lazyOnload'}
                onLoad={async () => {
                    if (!window.Kakao?.isInitialized()) {
                        await window.Kakao.init(KAKAO_KEY)
                        setIsKakaoInit(true)
                    }
                }}
            />
            <Script
                crossOrigin={'anonymous'}
                strategy={'lazyOnload'}
                src="https://connect.facebook.net/en_US/sdk.js"
                onLoad={() => {
                    if (window.FB) {
                        window?.FB?.init({
                            appId: '2416074971963619',
                            xfbml: true,
                            version: 'v14.0',
                        })
                    }
                }}
            />
            <Script
                crossOrigin={'anonymous'}
                strategy={'lazyOnload'}
                src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
            />
            <SetLoginStateContext.Provider value={{isKakaoInit}}>
                <MemoryRouter initialEntries={['/']}>
                    <LoginRoutes closeModal={closeModal} />
                    <RegisterRoutes closeModal={closeModal} />
                    <FindPasswordRoutes closeModal={closeModal} />
                    <Routes>
                        <Route path={'/login_other'} element={<ScreenLoginOtherMethod closeModal={closeModal} />} />
                        <Route path={'/'} element={<ScreenLogin closeModal={closeModal} />} />
                    </Routes>
                </MemoryRouter>
            </SetLoginStateContext.Provider>
        </>
    )
}

export default LoginModalRouter
