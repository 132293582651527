import {useRouter} from 'next/router'
import {useLoginModalStore} from '@store/LoginModalStore'

const useLoginModal = () => {
    const router = useRouter()
    const {redirectUrl, setRedirectUrl} = useLoginModalStore(state => ({
        redirectUrl: state.redirectUrl,
        setRedirectUrl: state.setRedirectUrl,
    }))

    const redirectAfterLogin = () => {
        if (router?.asPath === '/login') {
            router?.push(`/leaderboard`)
            setRedirectUrl(undefined)
        } else if (redirectUrl?.length > 0 && redirectUrl) {
            router?.push(redirectUrl)
            setRedirectUrl(undefined)
        }
        // if (redirectUrl && redirectUrl?.length > 0) {
        //     router?.push(redirectUrl)
        //     setRedirectUrl(undefined)
        // }
        //
        // if (equalString(router?.pathname, '/') && !redirectUrl) {
        //     router?.push(`/leaderboard`)
        //     setRedirectUrl(undefined)
        // }
    }

    return {redirectAfterLogin}
}

export default useLoginModal
